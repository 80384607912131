//  ======================================================
//  STRUCTURE.SCSS
//  Styles for site Wrappers
//  ======================================================

// -----------------------------------------------------
// SKIP CONTENT BUTTON
// -----------------------------------------------------

#skipContentWrapper {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10000;
}

#skipContentLink {
	display: block;
	width: 1px;
	height: 1px;
	overflow: hidden;

	&:hover,
	&:visited {
		display: block;
		width: 1px;
		height: 1px;
		overflow: hidden;
	}

	&:active,
	&:focus {
		position: relative;
		width: auto;
		height: auto;
		font-size: 15px;
		z-index: 10000;
		text-decoration: underline;
		padding: 20px;
		background: $brand-one;
		color: #FFF;
	}
}


// -----------------------------------------------------
// WRAPPERS
// -----------------------------------------------------

#uber {
	width: 100%;
	margin: 0 auto;
	background: #fff;
}

#uberMain {
	width: 100%;
	margin: 0 auto;
	padding: 0 0 30px 0;
	overflow: hidden;
	position: relative;
	z-index: 1;
	.scheduleModule & {
		z-index: inherit;
		overflow: visible;
	}
}

#main {
	position: relative;
	z-index: 9;
	display: flex;
	flex-direction: column;

	.scheduleModule & {
		overflow: visible;
	}

	@media (min-width: $screen-md) {
		flex-direction: row;
		flex-wrap: wrap;

		>* {
			min-width: 1px;
		}
	}
}

.ic-container-fluid {
	width: 94%;
	max-width: $container-max-width;
	padding: 0;
	position: relative;
	z-index: 1;
}

// -----------------------------------------------------
// CONTENT AREAS
// -----------------------------------------------------

#contentInt {
	background: #fff;
	overflow: hidden;
	width: 100%;
	padding: 0;
	min-height: 400px;
	order: 1;

	.scheduleModule & {
		overflow: visible;
	}

	@media (min-width: $screen-md) {
		padding: 0 30px 0 0;
		width: $content-width;

		.subNav-Left & {
			padding: 0 0 0 30px;
		}
	}

	@include xxl {
		padding-right: 75px;
	}

	.wide & {
		width: 100%;
		padding-right: 0;
	}
}

#printAreaContent {
	&:before {
		clear: left;
		content: "";
		display: table;
	}
}

// -----------------------------------------------------
// TOGGLE CONTENT (for testing only)
// -----------------------------------------------------

.toggleContent {
	margin-bottom: 15px;
	padding: .5em;
	font-size: 1em;
	line-height: 1.375;
	background-color: $gray-background;
}