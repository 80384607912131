@mixin socialIcons($bgShape: 'circle', $bgColor: 'brand', $color: white, $font-size: 20px) {
	$social-colors: (
		'facebook': #1877f2,
		'instagram': #f09433,
		'linkedin': #0077b5,
		'pinterest': #e60023,
		'rss': #f26522,
		'snapchat': #fffc00,
		'tiktok': #000,
		'twitter': #1da1f2,
		'vimeo': #1ab7ea,
		'youtube': #ff0000,
	) !default;

	$social-icons: (
		'facebook': $icon-facebook,
		'instagram': $icon-instagram,
		'linkedin': $icon-linkedin,
		'pinterest': $icon-pinterest,
		'rss': $icon-rss,
		'snapchat': $icon-snapchat,
		'tiktok': $icon-tiktok,
		'twitter': $icon-twitter,
		'vimeo': $icon-vimeo,
		'youtube': $icon-youtube,
	) !default;

    @each $name, $bg in $social-colors {
        &.#{$name} {
            font-size: 0;
            line-height: 1;

            &:after {
                @include icomoon();
                display: inline-block;
                font-size: $font-size;
            }
        }
    }

    @if ($bgShape=='circle') {
        @each $name, $bg in $social-colors {
            &.#{$name} {
                border-radius: 50%;
                overflow: hidden;

                &:after {
                    padding: 10px;
                }
            }
        }
    }
    @else if ($bgShape=='square') {
        &:after {
            padding: 10px;
        }
    }
    @else {
        // knockout is just no padding or background
    }

    @if ($bgColor == 'brand') {
        @each $name, $bg in $social-colors {
            &.#{$name} {
                &:after {
                    background-color: $bg;
                }
            }
        }

        &.snapchat {
            &:after {
                text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

                @supports (-webkit-text-stroke: 1px black) {
                    text-shadow: none;
                    -webkit-text-stroke: 1px black;
                }
            }
        }

        &.instagram {
            &:after {
                background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
            }
        }
    }
    @else {
        @each $name, $bg in $social-colors {
            &.#{$name} {
                &:after {
                    color: $color;
                    background-color: $bgColor;
                }
            }
        }
    }

	@if ($color=='brand') {
		@each $name, $color in $social-colors {
			&.#{$name} {
				&:after {
					color: $color;
				}
			}

            &.instagram {
				&:after {
					background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
					-webkit-background-clip: text;
  					-webkit-text-fill-color: transparent;
				}
			}

			&.snapchat {
				&:after {
					text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

                    @supports (-webkit-text-stroke: 1px black) {
                        text-shadow: none;
                        -webkit-text-stroke: 1px black;
                    }
				}
			}
		}
	}

    @each $name, $icon in $social-icons {
        &.#{$name} {
            &:after {
                content: $icon;
            }
        }
    }
}