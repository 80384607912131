//  ======================================================
//  CONTACTAUTOCOMPLETE.SCSS
//  styles for Contact module
//  ======================================================

#contactMainContainer {
	position: relative;
	z-index: 9;
	overflow: hidden;
	width: 100%;
	padding: 40px 32px 42px 30px;
	margin: 0 0 30px 0;
	color: #000;
	text-align: left;
	background: $table-row-bg;
	border-radius: 6px;

	.contactContainer {
		overflow: hidden;
	}

	.contactHeader {
		width: 100%;
		padding: 0;
	}

	.contactHeaderTitle {
		display: block;
		overflow: hidden;
		padding: 0;

		img {
			width: 22px;
			height: 22px;
			margin-right: 12px;
		}

		p {
			margin: 0 !important;
			font-size: rem(24px);
			font-weight: 600;
			line-height: 1;
		}
	}

	.contactContainer .contactBody {
		width: 100%;
		padding-top: 15px;

		@media (min-width: $screen-sm) {
			font-size: 1em;
		}

		p {
			margin: 0;
			font-size: rem(17px);
			line-height: (26px / 17px);
		}

		strong {
			font-weight: 600;
		}

		a {
			font-weight: 600;
		}
	}

	.contactContainer .autoCompleteContainer {
		text-align: left;
		padding: 10px 20px;
		width: 100%;
	}
}

#contactMainContainer_AutoComplete {
	width: 100px;
}