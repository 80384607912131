$topBar-bg: $gray-dark !default;
$topBar-color: color-contrast($topBar-bg, $colors: (white, $text-color)) !default;

#topBar {
  position: relative;
  z-index: 10;
  width: 100%;
  color: $topBar-color;
  background-color: $topBar-bg;

  .ic-container-fluid {
    display: flex;
    justify-content: flex-end;

    &:before,
    &:after {
      display: none;
    }
  }
}
