$footer-bg: white !default;
$footer-font-size: $font-size-small !default;
$footer-line-height: $line-height-base !default;
$footer-text-color: $gray-lighter !default;
$footer-link-color: white !default;
$footer-link-decoration: null !default;
$footer-link-hover-decoration: underline !default;
$footer-left-max-width: 225px !default;

@import 'footerNav';
@import 'footerNavMega';

#footer {
	width: 100%;
	background: $footer-bg;
	color: $footer-text-color;
	font-size: $footer-font-size;
	line-height: $footer-line-height;
	padding: 0;
	z-index: 1;
	position: relative;

	a {
		color: $footer-link-color;
		text-decoration: $footer-link-decoration;

		&:hover {
			text-decoration: $footer-link-hover-decoration;
		}
	}
}

.footerText {
	>* {
		text-overflow: ellipsis;
		overflow: hidden;
	}

	h2 {
		margin: 0;
		color: $brand-dark;
		font-size: rem(21px);
		font-weight: 600;
		line-height: (32px / 21px);
	}

	p {
		margin: 24px 0 0 0;
		color: $brand-dark;
		font-size: 1rem;
		font-weight: 400;
		line-height: (24px / 16px);

		@include xxl {
			margin-top: 1rem;
		}

		a {
			color: $link-color !important;
			font-weight: 600;
		}
	}

	&.contact-number {
		@include md-max {
			max-width: 234px;
			margin-right: auto;
			margin-left: auto;
		}

		p {
			&:last-of-type {
				color: #666666;
				font-size: rem(14px);
				line-height: (20px / 14px);
			}
		}
	}

	&.operating-hours {
		p {
			strong {
				font-weight: 600;
			}
		}
	}
}

/*==================
Footer Top
====================*/

.footerTop {
	width: 100%;
	padding: 30px 0 50px 0;
	text-align: center;

	@include lg {
		display: flex;
	}

	@include xxl {
		padding-top: 60px;
		padding-bottom: 64px;
	}
}

.footerTopLeft {
	@media (min-width: $screen-lg) {
		flex-shrink: 0;
		width: $footer-left-max-width;
		margin-right: 1rem;
	}

	@include xxl {
		margin-right: 64px;
	}
}

/*==================
Footer Logo
====================*/

.footerLogo {
	width: 220px;
	max-width: 100%;
	margin-right: auto;
	margin-left: auto;

	@include lg {
		margin-left: 0;
	}

	img {
		max-width: 100%;
		height: auto;
	}

	p {
		margin-top: 0;
		margin-bottom: 0;
	}
}

/*==================
Footer Bottom
====================*/

.footerBottom {
	@extend %text-truncate;
	width: 100%;
	min-height: 80px;
	padding: 36px 0;
	color: $brand-dark;
	text-align: center;
	border-top: 1px solid #DDDDDD;

	@include md {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}

	@include xl {
		justify-content: flex-start;
		padding: 32px 0 52px 0;
	}

	p {
		margin: 0;
		font-size: rem(18px);
		opacity: 0.8;
	}
}

a#esol {
	display: block;
	margin-top: 36px;
	color: $brand-dark;
	font-size: rem(18px);
	font-weight: 600;
	line-height: (28px / 18px);

	@include md {
		display: inline-block;
		margin-top: 0.5rem;
	}

	@include lg {
		margin-top: 0;
	}

	@include xl {
		display: block;
		margin-right: 0;
		margin-left: auto;
	}
}