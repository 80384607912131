$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-chevron-thin-left: "\e92b";
$icon-info-circle-thin: "\e92a";
$icon-alert-triangle: "\e928";
$icon-alerm: "\e929";
$icon-clock: "\e925";
$icon-connecting-services: "\e927";
$icon-service-updates: "\e926";
$icon-triangle-down: "\e924";
$icon-global: "\e923";
$icon-chat: "\e922";
$icon-bus: "\e91f";
$icon-schedule: "\e920";
$icon-trip-planner: "\e921";
$icon-minus-alt: "\e91d";
$icon-plus-alt: "\e91e";
$icon-share-alt: "\e91c";
$icon-pinterest: "\e91b";
$icon-snapchat: "\e914";
$icon-vimeo: "\e91a";
$icon-facebook: "\e916";
$icon-instagram: "\e917";
$icon-linkedin: "\e918";
$icon-twitter: "\e919";
$icon-snapchat-square: "\e915";
$icon-tiktok: "\e912";
$icon-youtube: "\e913";
$icon-rss-square: "\e902";
$icon-rss: "\e911";
$icon-pause: "\e900";
$icon-enlarge: "\e989";
$icon-shrink: "\e98a";
$icon-link: "\e9cb";
$icon-contrast: "\e9d5";
$icon-play: "\ea1c";
$icon-chevron-thin-right: "\e901";
$icon-chevron-thick: "\e903";
$icon-minus: "\e8f8";
$icon-arrow-right: "\e904";
$icon-bell: "\e905";
$icon-close: "\e906";
$icon-info-circle: "\e907";
$icon-phone-ring: "\e908";
$icon-search: "\e909";
$icon-subscribe: "\e90a";
$icon-plus: "\e90b";
$icon-print: "\e90c";
$icon-share: "\e90d";
$icon-text-size-decrease: "\e90e";
$icon-text-size-default: "\e90f";
$icon-text-size-increase: "\e910";
$icon-list: "\f03a";

