$footermega-col-count: 4 !default;
$footermega-col-spacing: 30px !default; // space between colums

//top 'main' links
$footermega-top-item-height: null !default;
$footermega-top-item-padding: 0 0 15px !default;
$footermega-top-item-color: null !default;
$footermega-top-item-font-size: 1.125rem !default;
$footermega-top-item-font-weight: $bold-text-font-weight !default;
$footermega-top-item-decoration: null !default;
$footermega-top-item-hover-decoration: underline !default;

//child links
$footermega-item-padding: 7px 0 !default;
$footermega-item-color: $brand-one !default;
$footermega-item-font-size: 1rem !default;
$footermega-item-font-weight: 600 !default;
$footermega-item-decoration: none !default;
$footermega-item-hover-decoration: underline !default;

.footerNavMega {
    display: block;

    @include sm {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1rem;
    }

    @include xxl {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        grid-gap: normal;
        max-width: calc(100% - 225px - 64px);
    }
}

.footerNavMegaCol {
    @extend %text-truncate;
    flex-grow: 1;
    width: 100%;
    padding: 0;
    margin-top: 60px;

    @include lg {
        margin-top: 22px;
        text-align: left;
    }

    @include xxl {
        flex: 0 0 auto;
        width: auto;
        max-width: 25%;
    }

    &:last-child {
        p {
            &:first-of-type {
                margin-top: 0;

                @include lg {
                    margin-top: 40px;
                }
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    h2 {
        padding: 0;
        margin: 0;
        font-size: rem(21px);
        font-weight: 600;
        line-height: (32px / 21px);

        a {
            color: $brand-dark !important;
            font-size: rem(21px);
            font-weight: 600;
            line-height: (32px / 21px);
        }
    }

    a {

        &.defaultButton-Primary,
        &.defaultButton-Secondary {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 12px 16px;
            font-size: 1rem;
            line-height: (20px / 16px);

            @include md {
                text-align: left;
            }

            @include xl {
                font-size: 1rem;
            }

            &:hover,
            &:focus {
                text-decoration: none !important;
            }

            img {
                width: 19px;
                margin-top: 1px;
                margin-right: 12px;
            }
        }
    }

    ul {
        @extend %resetList;
        margin-top: 0.5rem;

        &:before,
        &:after {
            display: none;
        }

        li {
            padding: $footermega-item-padding;
            line-height: 2;

            &:not(.topItem) {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;

                @include lg {
                    justify-content: flex-start;
                }
            }

            a {
                color: $footermega-item-color !important;
                font-size: $footermega-item-font-size;
                font-weight: $footermega-item-font-weight;
                text-decoration: $footermega-item-decoration;

                &:active,
                &:focus,
                &:hover {
                    text-decoration: $footermega-item-hover-decoration;
                }

                &.viewMore {
                    font-weight: 600;
                }
            }

            &.topItem {
                padding-top: 0;
                padding-bottom: 0;
                font-size: $footermega-top-item-font-size;
                font-weight: $footermega-top-item-font-weight;
                height: $footermega-top-item-height;
                color: $footermega-top-item-color;

                a {

                    &:active,
                    &:focus,
                    &:hover {
                        text-decoration: $footermega-top-item-hover-decoration;

                        >* {
                            text-decoration: $footermega-top-item-hover-decoration;
                        }
                    }
                }

                >h2,
                >h3,
                >h4 {
                    margin: 0;
                    padding: $footermega-top-item-padding;
                    font-size: inherit;
                    font-weight: inherit;
                    text-overflow: ellipsis;
                    color: inherit;
                    overflow: hidden;
                }
            }
        }
    }
}