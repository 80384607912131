#topNavContainer {
	display: none;

	@media (min-width: $screen-md) {
		display: inline-flex;
		align-items: center;
		max-width: 100%;
	}

	> * {
		display: inline-flex;
		align-items: center;
		min-width: 1px;
		height: 100%;
		overflow: hidden;

		+ * {
			margin-left: 15px;
		}
	}

	script {
		margin: 0;
	}
}

#topNav {
	display: inline-flex;

	> * {
		min-width: 1px;
		text-overflow: ellipsis;
		overflow: hidden;

		+ * {
			margin-left: 15px;
		}
	}

	a,
	a:link,
	a:visited {
		color: $topnav-link-color;
	}

	a {
		font-size: $topnav-font-size;
		line-height: $topnav-line-height;
		font-weight: $topnav-font-weight;
		text-decoration: $topnav-link-decoration;

		&:active,
		&:focus,
		&:hover {
			text-decoration: $topnav-link-hover-decoration;
		}
	}
}