$footerNav-item-spacing: 30px !default; // space between links
$footerNav-item-color: $brand-one !default;
$footerNav-item-font-size: rem(18px) !default;
$footerNav-item-font-weight: 600 !default;
$footerNav-item-decoration: none !default;
$footerNav-item-hover-decoration: underline !default;

#footerNav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    margin-top: 24px;
    color: $footerNav-item-color;
    text-align: center;
    
    @include md {
        margin-top: 0;
    }

    ul {
        margin: 0;
        padding: 0;

        @include md {
            display: flex;
        }

        li {
            display: flex;
            align-items: center;
            justify-content: center;
            list-style-type: none;

            @include md {
                margin-left: 1rem;
            }

            @include xl {
                margin-left: 2rem;
            }

            a {
                display: inline-block;
                color: $footerNav-item-color;
                font-size: $footerNav-item-font-size;
                font-weight: $footerNav-item-font-weight;
                text-decoration: $footerNav-item-decoration;
                padding: 0;
                max-width: 100%;
                text-align: center;
                text-overflow: ellipsis;
                overflow: hidden;

                &:hover,
                &:focus,
                &:active {
                    text-decoration: $footerNav-item-hover-decoration;
                }
            }
        }
    }


}

.footerText a,
.footerText a:link,
.footerText a:visited {
    color: $footerNav-item-color;

    &:hover,
    &:focus,
    &:active {
        text-decoration: $footerNav-item-hover-decoration;
    }
}