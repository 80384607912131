$header-bg: white !default;
$header-color: color-contrast($header-bg, $colors: (white, $text-color)) !default;
$header-left-width: 260px !default;

header {
	position: relative;
	z-index: 10;

	#headerOuter,
	.header-upper {
		position: relative;
		z-index: 10;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		width: 100%;
		color: $header-color;
		background-image: linear-gradient(to left, $brand-one 50%, white 50%);

		&:before {
			position: absolute;
			top: 0;
			right: 24px;
			display: block;
			width: calc(60% - 24px);
			height: 100%;
			background-image: linear-gradient(to left, $brand-one calc(100% - 36px), #00A3E0 calc(100% - 36px) calc(100% - 18px), #7FD1EF calc(100% - 18px));
			transform: skewX(-12deg);
			content: '';

			@include md {
				width: calc(80% - 24px);
				background-image: linear-gradient(to left, $brand-one calc(100% - 60px), #00A3E0 calc(100% - 60px) calc(100% - 30px), #7FD1EF calc(100% - 30px));
			}

			@include xxl {
				width: (1284px - 24px);
			}

			@media (min-width: 1920px) {
				width: calc(70% - 24px);
			}

			@media (min-width: 2400px) {
				width: calc(65% - 24px);
			}

			@media (min-width: 3300px) {
				width: calc(60% - 24px);
			}
		}

	}

	@media (max-width: $screen-md - 1) {
		transform: none !important; // override JS embedded style
	}

	@media (min-width: $screen-md) {
		padding-right: 0;
		padding-left: 0;
		transition: all $transition-base;

		.scrolling & {
			position: sticky;
			top: 0;
			box-shadow: 0 3px 6px rgba(#000, 0.20);
		}

		.scrollingDownward & {
			transition-delay: 0.1s;
		}
	}

	#headerOuter {
		.hideBanner & {
			&:after {
				position: absolute;
				bottom: 0;
				left: 0;
				display: block;
				width: 100%;
				height: 2px;
				background-color: #EEEEEE;
				content: '';
			}
		}
	}

	.header-upper {
		@include md {
			&:after {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 2px;
				background-color: #EEEEEE;
				content: '';
			}
		}

		&:before {
			width: calc(50% - 24px);

			@include sm {
				width: calc(70% - 24px);
			}

			@include md {
				width: calc(82% - 24px);
			}

			@media (min-width: 1800px) {
				width: calc(70% - 24px);
			}
		}

	}

	.header-upper-inner {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;
		max-width: 1400px;
		padding: 15px;
		margin-right: auto;
		margin-left: auto;
		background-image: linear-gradient(to left, $brand-one 40%, white 40%);

		@include xl {
			padding-top: 10px;
			padding-bottom: 10px;
		}

		@include xxl {
			padding-right: 0;
			padding-left: 0;
		}

		>a {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			font-size: rem(15px);
			line-height: 1;

			&:before {
				@include icomoon();
				display: inline-block;
				margin-right: 0.375rem;
				font-size: rem(15px);
				content: $icon-chevron-thin-left;
			}
		}
	}
}

.header-lower {
	background-color: white;
}

.header-lower-inner {
	max-width: 1400px;
	margin-right: auto;
	margin-left: auto;

	@include md {
		display: flex;
		align-items: stretch;
		justify-content: space-between;
		padding: 0 15px;
		background-color: white;
	}

	@include xxl {
		padding-right: 0;
		padding-left: 0;
	}

	>div {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 180px;
		max-height: 90px;
		flex-shrink: 0;
		width: 180px;
		max-height: 90px;
		margin-right: auto;
		margin-left: auto;
		padding: 1rem 0;

		@include lg {
			width: 216px;
			padding: 0;
			margin: 2rem 0;
		}

		>a {
			display: block;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}
}

#headerInner {
	display: flex;
	flex-wrap: wrap;
	padding-top: 1rem;
	padding-bottom: 1rem;

	@include md {
		padding-top: 4px;
		padding-bottom: 0;
	}

	@include xl {
		padding-top: 8px;
	}

	&:before,
	&:after {
		display: none;
	}
}

#headerLeft {
	flex-shrink: 0;
	display: flex;
	align-items: center;
	width: 100%;

	@media (max-width: $screen-md - 1) {
		justify-content: space-between;
		padding-right: 0.5rem;
		padding-left: 1rem;
	}

	@media (min-width: $screen-md) {
		padding-right: 1rem;
		width: $header-left-width;
	}
}

#headerRight {
	width: 100%;

	@media (min-width: $screen-md) {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		max-width: calc(100% - #{$header-left-width});
	}
}

.headerRight-upper {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	color: white;

	@include md {
		position: relative;
		flex-wrap: wrap;
		padding-bottom: 6px;
		max-width: 100%;
	}

	@include lg {
		margin-right: -12px;
	}

	.interior.landing & {
		padding-bottom: 0;
	}

	>nav {
		ul {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 0;
			margin: 0;

			li {
				display: flex;
				align-items: center;
				justify-content: center;
				list-style-type: none;
				margin-left: 1rem;

				a {
					@extend %text-truncate;
					max-width: 300px;
					color: white;
					font-size: rem(15px);
					font-weight: 400;
					line-height: (21px / 15px);
					white-space: nowrap;

					&:hover,
					&:focus {
						color: white;
						text-decoration: underline;
					}
				}
			}
		}
	}
}

#logo {
	a {
		display: block;
		outline-offset: 2px;

		.printLogo {
			display: none;
		}
	}

	h1,
	p {
		margin-top: 0;
		margin-bottom: 0;
		height: 100%;
		font-size: inherit;
		line-height: 1;
	}

	img {
		width: 100px;

		@include lg {
			width: 180px;
		}
	}
}

.header-btn {
	@extend %text-truncate;
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 100%;
	margin: 0 0 0 1.25rem;

	@include md-max {
		width: 100%;
		margin: 0;
	}

	@include md {
		max-width: 240px;
		margin: 0.5rem 0 0.5rem 1.25rem;
	}

	@include xl {
		max-width: 200px;
		padding: 12px 12px 12px 0;
		margin-top: 0;
		margin-bottom: 0;
	}

	>a {
		@extend %text-truncate;
		position: relative;
		display: flex;
		overflow: visible;
		align-items: center;
		justify-content: center;
		max-width: 100%;
		height: $search-height;
		padding-top: 0;
		padding-bottom: 0;
		color: $brand-one !important;
		font-size: rem(17px) !important;
		white-space: nowrap;
		background-color: white;
		border-color: white !important;

		@include md-max {
			width: 100%;
			margin: 0;
			background-color: #F5F5F5;
			border-color: #F5F5F5 !important;
			border-radius: 0;
		}

		@include lg-max {
			justify-content: space-between;
			padding-right: 1.5rem;
			padding-left: 1.5rem;
		}

		@include lg {
			padding-right: 0.5rem;
			padding-left: 0.5rem;
		}

		@include xxl {
			padding-right: 21px;
			padding-left: 21px;
		}

		&:hover,
		&:focus {
			border-color: darken($brand-four, 10%) !important;
		}

		&:after {
			position: absolute;
			top: -14px;
			right: -14px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 2rem;
			height: 2rem;
			color: $brand-dark;
			font-size: rem(17px);
			line-height: 1;
			background-color: #FFC117;
			border-radius: 50%;
			content: attr(data-count);

			@include xl-max {
				position: static;
				width: 27px;
				height: 27px;
				margin-left: 1rem;
				font-size: rem(14px);
			}
		}
	}
}