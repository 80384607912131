@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?p5ktco') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?p5ktco') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?p5ktco##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-thin-left {
  &:before {
    content: $icon-chevron-thin-left; 
  }
}
.icon-info-circle-thin {
  &:before {
    content: $icon-info-circle-thin; 
  }
}
.icon-alert-triangle {
  &:before {
    content: $icon-alert-triangle; 
  }
}
.icon-alerm {
  &:before {
    content: $icon-alerm; 
  }
}
.icon-clock {
  &:before {
    content: $icon-clock; 
  }
}
.icon-connecting-services {
  &:before {
    content: $icon-connecting-services; 
  }
}
.icon-service-updates {
  &:before {
    content: $icon-service-updates; 
  }
}
.icon-triangle-down {
  &:before {
    content: $icon-triangle-down; 
  }
}
.icon-global {
  &:before {
    content: $icon-global; 
  }
}
.icon-chat {
  &:before {
    content: $icon-chat; 
  }
}
.icon-bus {
  &:before {
    content: $icon-bus; 
  }
}
.icon-schedule {
  &:before {
    content: $icon-schedule; 
  }
}
.icon-trip-planner {
  &:before {
    content: $icon-trip-planner; 
  }
}
.icon-minus-alt {
  &:before {
    content: $icon-minus-alt; 
  }
}
.icon-plus-alt {
  &:before {
    content: $icon-plus-alt; 
  }
}
.icon-share-alt {
  &:before {
    content: $icon-share-alt; 
  }
}
.icon-pinterest {
  &:before {
    content: $icon-pinterest; 
  }
}
.icon-snapchat {
  &:before {
    content: $icon-snapchat; 
  }
}
.icon-vimeo {
  &:before {
    content: $icon-vimeo; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-snapchat-square {
  &:before {
    content: $icon-snapchat-square; 
  }
}
.icon-tiktok {
  &:before {
    content: $icon-tiktok; 
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube; 
  }
}
.icon-rss-square {
  &:before {
    content: $icon-rss-square; 
  }
}
.icon-rss {
  &:before {
    content: $icon-rss; 
  }
}
.icon-pause {
  &:before {
    content: $icon-pause; 
  }
}
.icon-enlarge {
  &:before {
    content: $icon-enlarge; 
  }
}
.icon-shrink {
  &:before {
    content: $icon-shrink; 
  }
}
.icon-link {
  &:before {
    content: $icon-link; 
  }
}
.icon-contrast {
  &:before {
    content: $icon-contrast; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-chevron-thin-right {
  &:before {
    content: $icon-chevron-thin-right; 
  }
}
.icon-chevron-thick {
  &:before {
    content: $icon-chevron-thick; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-bell {
  &:before {
    content: $icon-bell; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-info-circle {
  &:before {
    content: $icon-info-circle; 
  }
}
.icon-phone-ring {
  &:before {
    content: $icon-phone-ring; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-subscribe {
  &:before {
    content: $icon-subscribe; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-print {
  &:before {
    content: $icon-print; 
  }
}
.icon-share {
  &:before {
    content: $icon-share; 
  }
}
.icon-text-size-decrease {
  &:before {
    content: $icon-text-size-decrease; 
  }
}
.icon-text-size-default {
  &:before {
    content: $icon-text-size-default; 
  }
}
.icon-text-size-increase {
  &:before {
    content: $icon-text-size-increase; 
  }
}
.icon-list {
  &:before {
    content: $icon-list; 
  }
}

