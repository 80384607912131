#searchBtn {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    padding: 0;
    color: white;
    font-size: 19px;
    border: 0;
    background-color: transparent;
}

.searchContainer {
    width: 100%;

    @media (min-width: $screen-md) {
        width: 255px;
        margin: 0.5rem 0 0.5rem 1.5rem;
    }

    @include lg {
        margin-left: 1rem;
    }

    @include xl {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 1.5rem;
    }

    .interior.landing & {
        margin-top: 0;
        margin-bottom: 0;
    }

    .errorpage & {
        margin: 0 !important;
    }

    .search {
        display: flex;
        overflow: visible;
        height: $search-height;
        border-radius: $search-border-radius;

        &:before {
            flex: 0 0 2.75em;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }

        .searchInput,
        .searchButton {
            border: 0;
            text-decoration: none;
        }

        .searchInput {
            flex: 1 0 auto;
            padding: 16px;
            color: $search-text-color;
            font-size: rem(16px);
            font-weight: 400;
            line-height: (21px / 15px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            background: $search-bg;
            border-radius: $search-border-radius 0 0 $search-border-radius;
            transition: background-color 0.2s ease-in;

            &::placeholder {
                color: $search-text-color;
                font-size: rem(15px);
                font-weight: 400;
                line-height: (21px / 15px);
                opacity: 1;
            }

            &:focus {
                outline-offset: -2px;
            }
        }

        .searchButton {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 0 0 44px;
            font-size: 17px;
            background-color: $search-bg;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            transition: all 0.3s ease;

            @include md-max {
                color: white;
            }

            &:hover,
            &:focus {
                color: $search-bg;
                background-color: white;
            }

            &:focus {
                outline-offset: -2px;
            }

            .errorpage & {
                color: white;

                &:hover,
                &:focus {
                    color: $search-bg;
                }
            }
        }
    }

    #search_autocomplete {
        top: $search-height !important;
        left: 0;
        border: none !important;
    }

    #cludo-search-form,
    .cludo-search-form,
    .cludo-search_autocomplete {
        z-index: 1004;
    }
}

//Acessibility
#cludo-close {
    text-indent: -9999px !important;

    >button {
        height: 100% !important;
        text-indent: -9999px !important;
    }
}

#cludo-back-to-top>button {
    color: #222 !important;
}

// MODAL HELPERS

.modalCover {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($brand-dark, .86);
    z-index: 9990;
}

.modalBox {
    display: none;
    width: 90vw;
    position: fixed;
    top: 30vh;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: $container-max-width;
    border-radius: $search-border-radius;
    z-index: 9999;

    // Only fixed if screen is big enough, we want avoid screen lock w/ hidden elements living off screen
    @media (min-width: $screen-md) and (min-height: 1000px) {
        position: fixed;
    }
}

#searchModal .closeBtn {
    width: 34px;
    height: 34px;
    font-size: 32px;
    font-weight: 500;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -40px;
    right: 0;
    background-color: transparent;
    color: #fff !important;
    border: none;
    padding: 0;
    line-height: 1;
    border-radius: 50%;
    transition: ease background-color 0.250s;

    i {
        font-size: 1rem;
    }

    &:hover,
    &:focus {
        outline: none;
        background-color: rgba(#000, 0.80);
    }

    &:focus {
        outline: 2px solid -webkit-focus-ring-color;
        outline-offset: -2 px;
    }
}