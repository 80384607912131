/* TOAST MESSAGE (IE. CONTRAST MODE) */

$toast-top: 20px;
.toast {
    visibility: hidden;
    /* Hidden by default. Visible on click */
    position: fixed;
    top: 0;
    left: 50%;
    min-width: 280px;
    transform: translate3d(-50%, -100%, 0);
    background: rgba($brand-one, .9);
    border-radius: 90px;
    padding: 8px 16px;
    color: #fff;
    z-index: 1000;
    transition: all .24s ease-in-out;
    @media (min-width: $screen-md) {
        padding: 16px 32px;
    }
    p {
        margin: 0 !important;
        color: #fff;
        font-weight: 600 !important;
        font-size: rem(16px);
        line-height: 1.24;
        text-align: center;
        @media (min-width: $screen-md) {
            font-size: rem(21px);
        }
    }
}


/* Show the snackbar when clicking on a button (class added with JavaScript) */

.toast.show {
    visibility: visible;
    transform: translate3d(-50%, $toast-top, 0);
}