.serviceAdvisories {
    &__container {
        position: fixed;
        display: none;
        top: 0;
        right: -492px;
        z-index: 9999;
        width: 420px;
        height: 100vh;
        background-color: white;
        transition: all 0.3s ease;
    }

    &__header {
        position: relative;

        padding: 40px;
        background-color: $brand-one;

        .close-btn {
            position: absolute;
            top: 48px;
            left: -72px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            color: white;
            font-size: rem(34px);
            background-color: transparent;
            border: 0;
            border-radius: 50%;
            transition: background-color 0.3s ease;

            &:hover,
            &:focus {
                background-color: $brand-one;
            }
        }

        h2 {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            margin: 0;
            color: white;
        }

        p {
            margin: 0;

            &:not(.serviceAdvisories__number) {
                width: 100%;
                margin-top: 1rem;
            }

            a.defaultButton-quaternary {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: 9px 21px;
                color: white !important;
                font-size: 1rem;
                background-color: transparent;
                border-color: white;

                &:hover,
                &:focus {
                    i {
                        transform: scale(1.5);
                    }
                }

                i {
                    font-size: rem(12px);
                    transition: transform 0.3s ease;
                }
            }
        }
    }

    &__body {
        overflow: auto;
        height: calc(100vh - 188px);
        padding: 45px;
    }

    &__item {
        padding: 20px 0;
        border-bottom: 1px solid #DDDDDD;

        &:first-child {
            padding-top: 0;
        }

        a {
            display: inline-block;

            h3 {
                margin: 0;
                color: $brand-one;
                font-size: rem(20px);
                line-height: (28px / 20px);
            }
        }



        p {
            &:first-of-type {
                margin: 0.875rem 0 0 0;
                color: $brand-dark;
                font-size: rem(18px);
                font-weight: 600;
                line-height: (26px / 18px);
            }

            &:last-of-type {
                margin: 0;
                color: $brand-dark;
                font-size: rem(17px);
                line-height: (26px / 17px);
            }
        }
    }
}