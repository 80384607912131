#translateContainer {
	flex-shrink: 0;
	position: relative;
	width: 100%;
	margin-left: 1rem;

	@include md-max {
		padding: 0.25rem 0.75rem 1rem 1rem;
		margin: 0;
		background-color: #fff;
	}

	@media (min-width: $screen-md) {
		width: auto;
		max-width: 122px;
	}

	.goog-te-gadget {
		color: inherit;
		font-size: 0;

		>* {
			&:not(div) {
				display: none;
			}
		}

		>div {
			select {
				width: 100%;
				padding-left: 1.25rem;
				margin: 0 !important;
				font-family: $font-family-sans-serif !important;
				font-size: 1rem;
				background: transparent url('../../en/resourcesGeneral/global.svg') 0 50% / 14px no-repeat;
				border: 0;
				appearance: none;

				@include md-max {
					color: $brand-one;
					font-size: 1rem;
					font-weight: 400;
					line-height: 1.5rem;
					background-image: url('../../en/resourcesGeneral/global-blue.svg');
				}

				@include md {
					width: 86px;
				}

				&:hover,
				&:focus {
					cursor: pointer;
					text-decoration: underline;
				}

				option {
					color: #000;
				}
			}
		}
	}

	.goog-te-gadget-simple {
		display: block;
		padding: 0;
		font-size: inherit;
		background: transparent;
		border: 0;

		>span {
			display: inline-flex;
			align-items: center;
			justify-content: flex-start;
		}
	}

	.goog-te-menu-value {
		display: inline-flex;
		align-items: center;
		justify-content: flex-start;
		height: 18px;
		margin: 0;
		padding: 0;
		color: $topnav-link-color;

		@include md-max {
			height: 24px;
		}

		>span:first-of-type {
			display: inline-flex !important;
			align-items: center;
			justify-content: flex-start;
			font-family: $font-family-sans-serif !important;

			@include md-max {
				color: $brand-one;
				font-size: 1rem;
				font-weight: 400;
				line-height: 1.5rem;
			}

			&:before {
				display: block;
				margin-right: 6px;
				font-family: 'icomoon';
				font-size: 14px;
				content: $icon-global;
			}

			@media (min-width: $screen-md) {
				color: white;
				font-size: 1rem;
				line-height: 14px;
				font-weight: $topnav-font-weight;
				text-decoration: $topnav-link-decoration;
			}
		}

		&:hover span,
		&:active span,
		&:focus span {
			text-decoration: $topnav-link-hover-decoration;
		}
	}

	.goog-te-gadget-simple>img,
	.goog-te-menu-value img,
	.goog-te-menu-value span {
		display: none;
	}

	.goog-te-menu-value span:first-child {
		display: inline-block;
	}
}

// Adjust content and header so translate top bar doesn't cover anything
.skiptranslate {
	&:not([style="display: none;"]) {

		~div #uber,
		~#uber {

			header {
				top: 38px !important;
			}
		}
	}
}