//  ======================================================
//  SETTINGS.SCSS
//  All varibles live here
//  ======================================================

@import url("https://use.typekit.net/rrx4jsd.css");
@import 'mixins';

// BREAKPOINTS - values also need to be changed in responsiveScripts.js

// MIN WIDTH VALUES
$screen-xxl: 1600px;
$screen-xl: 1400px;
$screen-lg: 1200px;
$screen-md: 992px;
$screen-sm: 768px;
$screen-xs: 480px;

// COLORS

$gray-base: #000;
$gray-darker: #222;
$gray-dark: #333;
$gray: #666;
$gray-light: #ccc;
$gray-lighter: #efefef;

$gray-background: #eee; //Update based on concepts
$gray-border: #d4d4d4; //Update based on concepts

$brand-one: #006EC7; // blue
$brand-two: #00837C; // green
$brand-two-alt: #00A299; // green
$brand-three: #BAD405; // purple
$brand-four: #FFFFFF; // orange
$brand-five: #9561A8; // red
$brand-dark: #292929; // red
$brand-light: #EEEEEE; // red

$emphasis-primary: #B8E5F6;
$emphasis-primary-alt: $brand-one;
$emphasis-secondary: #BCE6E4;
$emphasis-secondary-alt: #292929;
$emphasis-tertiary: #E7D1EF;
$emphasis-tertiary-alt: #9561A8;
$emphasis-grey: #E2E2E2;
$emphasis-grey-alt: $emphasis-grey;

$alert-green: #4C832C;
$alert-yellow: #F0B844;
$alert-blue: #245EB2;
$alert-red: #DB2C17;

$body-bg: white; // default background color on body

// TEXT STYLES

$text-color: $brand-dark;

$system-fonts: -apple-system,
BlinkMacSystemFont,
'avenir next',
avenir,
'Segoe UI',
'lucida grande',
'helvetica neue',
helvetica,
'Fira Sans',
roboto,
noto,
'Droid Sans',
cantarell,
oxygen,
ubuntu,
'franklin gothic medium',
'century gothic',
'Liberation Sans',
sans-serif;
$font-family-sans-serif: urw-din,
helvetica,
arial,
sans-serif;
$font-family-serif: Georgia,
'Times New Roman',
Times,
serif;
$font-family-base: $font-family-sans-serif;

$font-family-alternate: $font-family-sans-serif;
$font-family-alternate-heading: $font-family-sans-serif;

$font-size-base: 16px;
$font-weight-base: 400;
$line-height-base: (30px / 18px);

$bold-text-font-family: inherit;
$bold-text-font-weight: bold;

$headings-font-family: inherit;
$headings-font-weight: 600;
$headings-line-height: 47px;
$headings-color: inherit;

$font-size-large: 1.125rem; //$font-size-base + 2
$font-size-small: 0.875rem; //$font-size-base - 2

$font-size-h1: rem(40px);
$text-color-h1: inherit;
$font-weight-h1: $headings-font-weight;

$font-size-h2: rem(32px);
$text-color-h2: inherit;
$font-weight-h2: $headings-font-weight;

$font-size-h3: rem(28px);
$text-color-h3: inherit;
$font-weight-h3: $headings-font-weight;

$font-size-h4: rem(24px);
$text-color-h4: inherit;
$font-weight-h4: $headings-font-weight;

$font-size-h5: rem(20px);
$text-color-h5: inherit;
$font-weight-h5: $headings-font-weight;

$font-size-h6: rem(18px);
$text-color-h6: inherit;
$font-weight-h6: $headings-font-weight;

$font-size-caption: $font-size-h3;
$text-color-caption: inherit;
$font-weight-caption: $bold-text-font-weight;

$font-size-intro: $font-size-large;
$text-color-intro: inherit;
$font-weight-intro: inherit;

$link-color: $brand-one;
$link-font-weight: 600;
$link-decoration: none;
$link-hover-color: darken($link-color, 5%);
$link-hover-decoration: underline;
$link-visited-color: $link-hover-color;
$link-visited-hover-color: $link-visited-color;


// NEWS MODULE COLOURS
$news-link-colour: $link-color;
$news-back-to-search: $news-link-colour;
$news-back-to-search-arrow: $news-back-to-search;
$news-post-date: $gray;

$news-subscribe-button-background: $brand-one;
$news-subscribe-button-foreground: color-contrast($news-subscribe-button-background, $colors: (#fff, #000), $tolerance: 0);
$news-primary-button-background: $brand-one;
$news-primary-button-foreground: color-contrast($news-primary-button-background, $colors: (#fff, #000), $tolerance: 0);
$news-like-button-background: $gray-lighter;
$news-like-button-foreground: $news-link-colour;
$news-like-button-heart: $news-like-button-foreground;
$news-post-comment: $news-link-colour;
$news-login-button-background: $gray-lighter;
$news-login-button-foreground: $news-link-colour;
$news-post-button-background: $brand-two;
$news-post-button-foreground: color-contrast($news-post-button-background, $colors: (#fff, #000), $tolerance: 0);
$news-reply-button-foreground: $news-link-colour;

$news-date-highlight: $brand-one;
$news-range-highlight: #dfecec;

$news-pagination-background: white;
$news-pagination-foreground: $news-link-colour;
$news-pagination-active-background: $news-pagination-foreground;
$news-pagination-active-foreground: color-contrast($news-pagination-active-background, $colors: (#fff, #000), $tolerance: 0);

$news-toast-background: $brand-one;
$news-toast-top: 100px;

$news-carousel-background: $brand-one;

// COMPONENTS

$border-radius-base: rem(6px);
$border-radius-large: .5rem;
$border-radius-small: .125rem;

$box-shadow-base: 0 .25rem .5rem rgba(black, .2);
$box-shadow-large: 0 .5rem 1rem rgba(black, .2);
$box-shadow-small: 0 .125rem .25rem rgba(black, .2);

$transition-base: .3s cubic-bezier(0.23, 1, 0.32, 1);

/// TABLES

$table-bg: transparent;
$table-border: solid 2px #DDDDDD; //ie solid 1px color
$table-border-radius: 0; // add border-collapse: separate if you want rounded corner

$table-text-color: inherit;
$table-cell-padding: 11px;
$table-row-bg: #E5F0F9;
$table-altrow-bg: white;
$table-cell-border-color: $gray-light;

$table-titlerow-bg: white;
$table-titlerow-padding: 10px;
$table-titlerow-text-align: left;
$table-titlerow-text-color: black;
$table-titlerow-font-size: 1em;
$table-titlerow-font-weight: bold;
$table-titlerow-border-bottom: solid 4px $brand-one;

// STRUCTURE
$container-width: 94%;
$container-padding: 3%;
$container-max-width: 1400px;
$container-max-width-wide: 1600px;

// SEARCH WIDGET
$search-height: 46px; // .search, searchText, .goButton
$search-bg: #005EA9;
$search-border-color: $gray-light;
$search-border-radius: $border-radius-base;
$search-text-color: white;
$search-text-padding: 3px 10px;
$search-font-size: inherit;
$search-button-bg: transparent; // button color

$topnav-font-size: rem(14px) !default;
$topnav-line-height: inherit !default;
$topnav-font-weight: normal !default;
$topnav-link-color: inherit !default;
$topnav-link-decoration: none !default;
$topnav-link-hover-decoration: underline !default;

// INTERIOR CONTAINER WIDTHS
$subnav-width: 25%; // $subnav-width + $content-width must = 100%;
$content-width: 75%; // #contentInt width
$right-col-width: 255px; // #intFloatRight width;
$actions-width: 200px;