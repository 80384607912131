.socialLinks {
	@extend %text-truncate;
	width: 100%;
	margin: 2rem 0 0 0;
	text-align: center;

	@media (min-width: $screen-lg) {
		margin-top: 60px;
		text-align: left;
	}

	h2 {
		@extend %text-truncate;
		margin: 0;
		color: $brand-dark;
		font-size: rem(21px);
		font-weight: 600;
		line-height: (32px / 21px);
	}

	ul {
		display: inline-flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 0;
		margin: 1rem 0 0 0;

		@include lg {
			margin-top: 0.5rem;
		}

		li {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			margin: 0 6px;
			line-height: 1;
			list-style-type: none;

			@include lg {
				margin: 6px 5px 0 0;
			}

			a {
				@include socialIcons();
				display: inline-block;
			}
		}
	}
}