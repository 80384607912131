#idLikeTo {
	flex-shrink: 0;

	// a11y trap focus styles
	&:focus {
		outline: none;

		#idLikeToBtn,
		#idLikeToDropdown {
			@extend %defaultFocus;
		}
	}
}

#idLikeToBtn {
	padding: 0.375em 0.75em;
	font-size: $topnav-font-size;
	white-space: nowrap;

	&:after {
		@include icomoon();
		content: $icon-chevron-thick;
		display: inline-block;
		margin-left: 0.375em;
		transform: rotate(90deg);
		font-size: 1.25em;
		pointer-events: none;
	}

	&[aria-expanded="true"] {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;

		&:after {
			transform: rotate(270deg);
		}

		+ #idLikeToDropdown {
			display: inline-flex;
		}
	}
}

#idLikeToDropdown {
	display: none;
	position: absolute;
	margin-top: -5px;
	padding: .5rem;
	top: 100%;
	right: 0;
	z-index: -1;
	background-color: $gray-background;
	border-radius: $border-radius-base 0 $border-radius-base $border-radius-base;
	box-shadow: $box-shadow-base;
	overflow: hidden;

	li {
		margin-bottom: 0.375em;
		line-height: 1.375;
	}

	ul {
		flex-grow: 1;
		margin: 0;
		padding: 0 .5rem;
		width: 100%;
		list-style: none;

		ul {
			margin-top: 0.75em;
			padding: 0;
			font-size: 16px;
		}
	}

	> ul {
		font-size: 20px;
	}

	.viewMore {
		display: inline-block;
		margin-top: .75em;
	}
}