//======================================================================
// BOXDATES.SCSS
//======================================================================

$boxDates-font-size: inherit;
$boxDates-margin: 0 0 40px;
$boxDates-padding: 10px 20px; // applies to -outline and -raised modifiers
$boxDates-box-shadow: 0 0.25em 0.5em rgba(0,0,0,.2); // applies to -raised modifiers
$boxDates-border: 1px solid #ccc;
$boxDates-border-bottom: 4px solid $brand-one;
$boxDates-border-radius: 0px;
$boxDates-list-break: $screen-sm;

$boxDates-header-font-size: em(22px);
$boxDates-header-font-weight: 400;
$boxDates-header-padding: 25px 0px 20px;
$boxDates-header-border-bottom: 1px solid #eee;

$boxDates-body-padding: 0;

$boxDates-footer-show: false;	// true or false
$boxDates-footer-padding: 25px 0px 20px;
$boxDates-footer-border-top: 1px solid #eee;

$dateList-bg: transparent !default;
$dateList-item-border: solid 1px #ddd;
$dateList-item-padding: 15px 0;

$dateList-title-font-size: 1.125em;
$dateList-title-font-weight: 500;
$dateList-title-color: #000;
$dateList-title-padding: 3px 0;

$dateList-details-font-size: 1em;
$dateList-details-font-weight: 400;
$dateList-details-color: #333;
$dateList-details-padding: 3px 0;

$dateList-fulldate-font-size: 0.9375em;
$dateList-fulldate-font-weight: 400;
$dateList-fulldate-color: #555;

$dateList-boxdate-date-padding: 8px 16px 8px 0;
$dateList-boxdate-date-border-width: 0 1px 0 0;
$dateList-boxdate-date-border-color: #ccc;
$dateList-boxdate-date-bgcolor: #fff;
$dateList-boxdate-month-font-size: .875em;
$dateList-boxdate-month-font-weight: 500;
$dateList-boxdate-month-color: #000;
$dateList-boxdate-day-font-size: 1.75em;
$dateList-boxdate-day-font-weight: 500;
$dateList-boxdate-day-color: $brand-one;

$dateList-image-width: 200px;

// BOX
.boxDates {
	flex: 1 0 auto;
	display: flex;
	flex-flow: column nowrap;
	font-size: $boxDates-font-size;
	margin: $boxDates-margin;
	background: #fff;
	overflow: hidden;

	&-raised {
		@extend .boxDates;
		box-shadow: $boxDates-box-shadow;
		padding: $boxDates-padding;

		&-images {
			@extend .boxDates-raised;
		}

		.interiorfeed & {
			width: calc(100% - 5px);
		}
	}

	&-outline {
		@extend .boxDates;
		border: $boxDates-border;
		border-bottom: $boxDates-border-bottom;
		border-radius: $boxDates-border-radius;
		padding: $boxDates-padding;

		&-images {
			@extend .boxDates-outline;
		}
	}

	&-list {
		@extend .boxDates;

		&-images {
			@extend .boxDates-list;
		}
	}
}

// BOX HEADER (OPTIONAL)
.boxDates_header {
	flex: 0 0 auto;
	display: flex;
	flex-flow: row nowrap;
	align-content: center;
	padding: $boxDates-header-padding;
	border-bottom: $boxDates-header-border-bottom;

	.boxDates_title, h2 {
		margin: 0;
		padding: 0;
		line-height: 1.24;
		overflow: hidden;
		font-size: $boxDates-header-font-size;
		color: #000;
		font-weight: 600;
	}

	a,
	a.viewMore,
	p {
		margin-left: auto;
	}

	a {
		color: $brand-one;
		font-weight: 700;
	}

	p {
		margin: 0;
	}
}

// BOX BODY: MAIN CONTENT AREA
.boxDates_body {
	flex: 1 1 auto;
	padding: $boxDates-body-padding;

	> * {
		overflow: hidden;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}

// BOX FOOTER: USER FOR 'MORE' LINK
.boxDates_footer {
	flex: 0 0 auto;
	padding: $boxDates-footer-padding;
	border-top: $boxDates-footer-border-top;
	overflow: hidden;

	@if $boxDates-footer-show == false {
		display: none;
	}

	> * {
		margin: 0;
		padding: 0;
		overflow: hidden;
	}

	a {
		color: $brand-one;
		font-weight: 700;
	}
}

//======================================================================
// DATELIST
//======================================================================

//RESET LISTS
.dateList {
	padding: 0;
}

.dateList,
.dateList_item {
	margin: 0 !important;
	list-style: none;
}

.dateList {
	background: $dateList-bg;

	&-news {
		@extend .dateList;
	}

	&-events {
		@extend .dateList;
	}
}

.dateList_item {
	display: flex;
	flex-direction: column;
	padding: $dateList-item-padding;
	border-top: $dateList-item-border;
	overflow: hidden;

	@media (min-width: $screen-md){
		.fbg-col-sm-6 & {
			flex-direction: row;
		}
	}

	&:first-child {
		border-top: 0;
	}

	.interiorfeed & {
		&:first-child {
			border-top: $dateList-item-border;
		}

		&:last-child {
			border-bottom: $dateList-item-border;
		}
	}

	.interiorfeed .boxDates-raised &,
	.interiorfeed .boxDates-outline & {
		&:first-child {
			border-top: 0;
		}
		&:last-child {
			border-bottom: 0;
		}
	}

	.dateList_image {
		display: none;

		.boxDates-raised-images &,
		.boxDates-outline-images &,
		.boxDates-list-images & {
			display: block;
			flex: 0 0 100%;
			width: 100%;
			margin-bottom: 20px;

			.fbg-col-sm-6 & {
				@media (min-width: $screen-md) {
					margin-bottom: 0;
					margin-right: 20px;
					flex: 0 0 $dateList-image-width;
					width: $dateList-image-width;
				}
			}

			img {
				width: 100%;
				height: auto;
			}
		}
	}
}

.dateList_link {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	cursor: pointer;
	align-items: stretch;
	width: 100%;

	.dateList-news & {
		flex-flow: column nowrap;
	}

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

.dateList_date {
	flex: 0 0 auto;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	padding: $dateList-boxdate-date-padding;
	border-style: solid;
	border-width: $dateList-boxdate-date-border-width;
	border-color: $dateList-boxdate-date-border-color;
	border-radius: 0;
	background-color: $dateList-boxdate-date-bgcolor;
	text-align: center;
	text-transform: uppercase;
	font-size: 1em;
	line-height: 1.2;
	font-weight: normal;

	.dateList-news & {
		display: none;
	}
}

.dateList_month {
	text-decoration: none;
	font-size: $dateList-boxdate-month-font-size;
	color: $dateList-boxdate-month-color;
	font-weight: $dateList-boxdate-month-font-weight;

	.dateList-news & {
		display: none;
	}
}

.dateList_day {
	font-size: $dateList-boxdate-day-font-size;
	color: $dateList-boxdate-day-color;
	font-weight: $dateList-boxdate-day-font-weight;

	.dateList-news & {
		display: none;
	}
}

.dateList_meta {
	display: flex;
	flex-direction: column;
	width: 100%;

	.dateList_title {
		padding: $dateList-title-padding;
		color: $dateList-title-color;
		font-size: $dateList-title-font-size;
		font-weight: $dateList-title-font-weight;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		overflow: hidden;

		.dateList_link:hover &,
		.dateList_link:focus & {
			text-decoration: underline;
		}

		.dateList-events & {
			padding-left: 10px;
		}
	}

	.dateList_details {
		padding: $dateList-details-padding;
		color: $dateList-details-color;
		font-size: $dateList-details-font-size;
		font-weight: $dateList-details-font-weight;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		overflow: hidden;

		.dateList_link:hover &,
		.dateList_link:focus & {
			text-decoration: none;
		}

		.dateList-events & {
			padding-left: 10px;
		}
	}

	.dateList_full {
		padding: $dateList-details-padding;
		color: $dateList-fulldate-color;
		font-size: $dateList-fulldate-font-size;
		font-weight: $dateList-fulldate-font-weight;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		overflow: hidden;

		.dateList-events & {
			display: none;
		}
	}
}
