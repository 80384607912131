//  ======================================================
//  MOBILENAV.SCSS
//  styles for main mobile nav
//  ======================================================

.mobileNav {
	display: table-cell;
	width: 110px;
	text-align: right;
	vertical-align: middle;

	@media (min-width: $screen-md) {
		display: none;
	}

	a,
	button {
		vertical-align: middle;
	}
}

.mobileNav a {
	display: inline-block;
	width: 45px;
	height: 45px;
	margin-left: 4px;
	font-size: 30px;
	text-align: center;
	text-decoration: none;
	color: $text-color;
	background-color: $gray-background;
	border: 1px solid $gray-border;
	border-radius: $border-radius-small;

	&:focus,
	&:hover {
		color: $text-color;
	}
}

.mobileNav a img {
	width: 45px;
	height: 45px;
	margin: 0 auto;
	display: block;
}

.menuTrigger {
	//burger($width, $height, $border, $border-radius, $background, $padding-x, $padding-y, $bar-width, $bar-height, $gutter, $color, $bar-border-radius, $transition-duration)
	@include burger(36px, 36px, 0, 0, transparent, 0, 0, 22px, 2px, 5px, white, 0, 0.3s);

	&[aria-expanded="true"] {
		@include burger-to-cross;
	}
}