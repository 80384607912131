//  ======================================================
//  MAINNAV.SCSS
//  styles for main menu & mobile menu
//  ======================================================

$nav-font-size: rem(20px) !default;
$nav-line-height: rem(24px) !default;
$nav-font-weight: 600 !default;
$nav-font-family: inherit !default;
$nav-item-text-color: white !default;
$nav-item-text-align: left !default;
$nav-item-spacing: 1px !default; //gap between items
$nav-item-spacing-color: transparent !default; //color of divider between items
$nav-item-bg: $brand-one !default;
$nav-item-hover-bg: white !default;
$nav-item-hover-color: $brand-one !default;

$nav-dropdown-bg: white !default;
$nav-dropdown-border: $gray-light !default;
$nav-dropdown-padding: 25px 12px 25px 12px !default;
$nav-dropdown-border-radius: 3px !default;

$nav-dropdown-col-count: 3 !default; // number of columns in dropdown
$nav-dropdown-col-spacing: 1rem !default; // space between columns

$nav-dropdown-item-padding: 7px 0 !default;
$nav-dropdown-item-divider: $gray-light !default;
$nav-dropdown-item-font-size: $font-size-base !default;
$nav-dropdown-item-font-weight: normal !default;
$nav-dropdown-item-text-color: $brand-one !default;
$nav-dropdown-item-decoration: none !default;
$nav-dropdown-item-hover-decoration: underline !default;
$nav-dropdown-item-hover-bg: transparent !default;

$mobile-dropdown-bg: $brand-one !default;

$mobile-dropdown-item-padding: 10px !default;
$mobile-dropdown-item-divider: fade-out(white, .9) !default;
$mobile-dropdown-item-text-color: white !default;
$mobile-dropdown-item-text-align: center !default;
$mobile-dropdown-item-font-size: $font-size-large !default;
$mobile-dropdown-item-font-weight: normal !default;
$mobile-dropdown-item-active-bg: darken($mobile-dropdown-bg, 5%) !default;

$expander-width: 54px !default;

%mainNavHover {
	color: $nav-item-hover-color;
	background: $nav-item-hover-bg;
	box-shadow: 0 0 0 99px $nav-item-hover-bg;
}

#mainNav {
	position: absolute;
	display: none;
	top: 100%;
	left: 50%;
	transform: translateX(-50%);
	width: calc(100% + (100vw * 0.06));
	max-height: calc(100vh - 81px);
	overflow: auto;
	margin: 0;

	@include md {
		position: static;
		display: block !important;
		margin: 0;
		top: auto;
		width: auto;
		max-width: 100%;
		max-height: none;
		border-top: 1px solid rgba(black, 0.15);
		transform: none;
	}

	.interior.landing & {
		width: 100%;

		@include md {
			max-width: calc(100% - 216px);
			border-top: 0;
		}

		ul {
			padding: 0;
			margin: 0;

			@include md {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				height: 100%;
			}

			li {
				display: block;
				max-width: 100%;
				background-color: $brand-one;

				@include md {
					max-width: 20%;
					height: 100%;
					background-color: transparent;
				}

				a {
					@extend %text-truncate;
					position: relative;
					display: flex;
					align-items: center;
					height: 100%;
					padding: .75rem 1rem;
					color: white;
					border-bottom: 1px solid rgba(white, 0.15);

					@include md {
						justify-content: center;
						color: $brand-dark;
						font-size: rem(17px);
						border-bottom: 0;
					}

					&:hover,
					&:focus {
						text-decoration: none;

						@include md {
							&:after {
								opacity: 1;
							}
						}
					}

					&:after {
						position: absolute;
						bottom: 0;
						left: 50%;
						transform: translateX(-50%);
						width: calc(100% - 32px);
						height: 5px;
						background-color: $brand-three;
						opacity: 0;
						content: '';
						transition: opacity 0.3s ease;
					}
				}
			}
		}

		>ul:nth-of-type(2) {
			@include md-max {
				padding: 0.5rem 0 0 0;
				background-color: #fff;

				li {
					background-color: transparent;

					a {
						padding: .25rem 1rem;
						color: $brand-one;
					}
				}
			}
		}
	}

	>ul {
		@include md-max {
			&:last-of-type {
				padding: 0.5rem 0 0 0;
				margin: 0;
				background-color: #fff;

				li {
					background-color: transparent;
					list-style-type: none;

					a {
						position: relative;
						display: block;
						padding: .25rem 1rem;
						width: 100%;
						color: $brand-one;
						font-family: $nav-font-family;
						font-size: 1rem;
						font-weight: 400;
						line-height: $nav-line-height;
						text-align: $nav-item-text-align;
						text-overflow: ellipsis;
						overflow: hidden;
						border-bottom: 1px solid rgba(#ffffff, 0.15);
					}
				}
			}
		}
	}
}

#nav {
	margin: 0;
	padding: 0;

	@media (min-width: $screen-md) {
		display: flex;
		justify-content: flex-end;
		width: 100%;
		max-width: 100%;
	}

	>li {
		display: flex;
		min-width: 1px;
		font-size: $nav-font-size;
		text-overflow: ellipsis;
		overflow: hidden;

		@media (max-width: $screen-md - 1) {
			flex-direction: column;
			background-color: $nav-item-bg;
		}

		@media (min-width: $screen-md) {
			max-width: 30%;
			border-width: 1px 1px 1px 0;

			&:first-child {
				a {
					padding-left: 0;

					&:before {
						left: 0;
						transform: none;
						width: calc(100% - 24px);
					}
				}
			}

			&:last-child {
				a {
					padding-right: 0;

					&:before {
						right: 0;
						left: auto;
						transform: none;
						width: calc(100% - 24px);
					}
				}
			}
		}

		&.sfHover {
			border-bottom: 0;
		}
	}

	&.nav--click {
		// click nav specific, see mainNav.js
	}

	&.nav--hover {

		// hover nav specific, see mainNav.js
		.dropdownViewAll {
			display: none;
		}
	}
}

a.mainNavItem {
	@extend %text-truncate;
	position: relative;
	display: block;
	padding: .75rem 1rem;
	width: 100%;
	max-width: 100%;
	color: $nav-item-text-color;
	font-family: $nav-font-family;
	font-size: $nav-font-size;
	font-weight: $nav-font-weight;
	line-height: $nav-line-height;
	text-align: $nav-item-text-align;
	text-overflow: ellipsis;
	overflow: hidden;
	transition: all 0.3s ease;

	@include md-max {
		flex-grow: 1;
		width: 100%;
		border-bottom: 1px solid rgba(#ffffff, 0.15);

		.noDropdown & {
			width: 100%;
		}
	}

	@include md {
		padding: 16px 24px 20px 24px;
	}

	@include xl {
		font-size: rem(24px);
		line-height: (34px / 24px);
	}

	&:before {
		.landing & {
			display: none !important;
		}

		@include md {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			width: calc(100% - 48px);
			height: 5px;
			content: '';
			opacity: 0;
			transition: all 0.3s ease;

			.hideBanner & {
				bottom: 2px;
			}
		}
	}

	.current & {
		&:before {
			@include md {
				background-color: white;
				opacity: 1;
			}
		}
	}

	&:focus,
	&:hover {
		color: white;
		text-decoration: none;

		@include md-max {
			color: $brand-one;
			background-color: white;
		}

		&:before {
			@include md {
				background-color: $brand-three;
				opacity: 1;
			}
		}
	}

	&.hasChildren {
		display: flex !important;
		align-items: center;
		justify-content: space-between;
		overflow: visible !important;

		&:after {
			display: block;
			margin-left: 14px;
			font-family: 'icomoon';
			font-size: 4.5px;
			content: $icon-triangle-down;
			transition: transform 0.3s ease;
		}

		&[aria-expanded="true"] {
			&:after {
				transform: rotate(180deg);
			}
		}
	}
}

.topNavMobile>a,
#browseAloudContainer a,
#browseAloudContainer button {
	@media (max-width: $screen-md - 1) {
		flex-grow: 1;
		display: block;
		position: relative;
		padding: .75rem 1rem;
		width: calc(100% - #{$expander-width});
		font-family: $nav-font-family;
		font-size: $nav-font-size;
		font-weight: $nav-font-weight;
		line-height: $nav-line-height;
		text-align: $nav-item-text-align;
		text-overflow: ellipsis;
		color: $nav-item-text-color;
		background: $nav-item-bg;
		overflow: hidden;

		.noDropdown & {
			width: 100%;
		}
	}
}

// combo box arrow for main nav
.mainNavItem_expander {
	cursor: pointer;
	width: $expander-width !important;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $gray-lighter;
	border: 0;
	border-left: solid 1px rgba(0, 0, 0, 0.15) !important;
	position: relative;
	-moz-appearance: none;
	-ms-appearance: none;
	-webkit-appearance: none;
	appearance: none;

	@media (min-width: $screen-md) {
		display: none;
	}

	.icon {
		width: 21px;
		height: 21px;
		display: block;
		cursor: pointer;
		position: relative;

		&:before,
		&:after {
			content: "";
			width: 16px;
			height: 2px;
			background: #000;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			transition: transform 350ms ease;
		}

		&:before {
			transform: rotate(180deg);
		}

		&:after {
			transform: rotate(90deg);
			transform-origin: center;
		}
	}

	&[aria-expanded="true"] {
		.icon {

			&:before,
			&:after {
				transform: rotate(0);
			}
		}
	}
}

// -----------------------------------------------------
// MAIN NAV DROP DOWN
// -----------------------------------------------------

#mainNav {
	.dropDownContainer {
		display: none;
		position: absolute;
		z-index: 1000;
		left: 0;
		top: 100%;
		width: 100%;
		padding: $nav-dropdown-padding;
		border: 1px solid $nav-dropdown-border;
		border-top: none;
		border-radius: 0 0 $nav-dropdown-border-radius $nav-dropdown-border-radius;
		background: $nav-dropdown-bg;
		border-radius: 0 0 $nav-dropdown-border-radius $nav-dropdown-border-radius;
		text-align: left;

		@media (max-width: $screen-md - 1) {
			flex: 1 1 auto;
			width: 100%;
			min-width: 0;
			padding: 0 !important;
			border: 0;
			border-top: solid 1px #ccc;
			padding: 10px 0;
			position: static;

			.dropdown {
				list-style: none;
				margin: 0;
				padding: 0;
				width: 100%;

				&:first-child {
					margin: 0;
				}

				li {
					display: block;
					width: 100%;
					background: none;
					border-bottom: 1px solid $nav-dropdown-item-divider;

					&:hover,
					&:focus {
						background: #f5f5f5;
					}

					a {
						display: block;
						width: 100%;
						padding: 8px 16px;
						font-size: $nav-dropdown-item-font-size;
						font-weight: $nav-dropdown-item-font-weight;
						text-decoration: $nav-dropdown-item-decoration;
						color: $nav-dropdown-item-text-color;
						overflow: hidden;

						.contrast-mode & {
							color: black;
						}
					}
				}

				&.dropDownRight {
					display: none;
				}

				&:nth-child(2) {
					li:last-child {
						border-bottom: none;
					}
				}
			}
		}

		@include md {
			padding: 0;
			border: 0;
		}

		@include xxl {
			left: 50%;
			transform: translateX(-50%);
			width: 1500px;
		}

		.dropdown {
			>li {
				float: none;

				>a {
					font-weight: 600;
				}

				ul {
					padding: 0;
					margin: 0;

					li {
						float: none;

						a {
							line-height: 1.25;
						}
					}
				}
			}

			@include md {
				padding: 1.5rem 2rem;

				>li {
					>a {
						display: inline-block;
						align-self: flex-start;
						width: auto;
						padding: 0.75rem 0 0 0;
						color: $brand-one;
						font-size: rem(17px);
						font-weight: 600;
						line-height: (23px / 17px);
						border-top: 0;
					}

					ul {
						padding: 0;
						margin: 0;

						li {
							display: flex;
							border-top: 1px solid #DDDDDD;

							a {
								display: inline-block;
								width: auto;
								padding: 0.375rem 0;
								color: $brand-dark;
								font-size: rem(15px);
								font-weight: 600;
								line-height: (21px / 15px);
								border-top: 0;
							}
						}
					}
				}
			}

			@include xxl {
				padding: 20px 50px;
			}
		}
	}

	.dropdown__outer {
		width: 100%;

		@include md-max {
			>.dropdown {
				>li {
					border-bottom: 0;

					>a {
						border-bottom: 1px solid $nav-dropdown-item-divider;
					}

					>ul {
						li {
							a {
								padding-left: 1.75rem;
								color: #000;
							}
						}
					}
				}
			}
		}

		@include md {
			flex: 0 0 67.5%;
			overflow: auto;
			width: auto;
			max-height: 500px;
		}
	}

	.dropdown {
		width: 100%/$nav-dropdown-col-count;
		padding: 0 $nav-dropdown-col-spacing;
		list-style: none;
		overflow: hidden;

		@include md {
			width: 100%;
			padding: 0;
		}

		&:not(.dropdownLeft):not(.dropdownRight) {
			@include md {
				column-count: 2;
			}
		}

		&:first-child {
			margin: 0;
		}

		li {
			float: left;
			width: 100%;
			background: none;

			a {
				display: block;
				width: 100%;
				color: $nav-dropdown-item-text-color;
				font-size: $nav-dropdown-item-font-size;
				font-weight: $nav-dropdown-item-font-weight;
				padding: $nav-dropdown-item-padding;
				text-decoration: $nav-dropdown-item-decoration;
				overflow: hidden;

				&:hover,
				&:active,
				&:focus {
					text-decoration: $nav-dropdown-item-hover-decoration;
					background-color: $nav-dropdown-item-hover-bg;
				}

				&.viewMore {
					border: 1px solid $nav-dropdown-item-divider;
					padding: $nav-dropdown-item-padding;
					padding-left: 15px;
					padding-right: 15px;
					font-weight: 600;
					position: relative;

					&:before {
						@include icomoon();
						content: $icon-chevron-thick;
						position: absolute;
						font-size: 20px;
						display: flex;
						align-items: center;
						top: 0;
						bottom: 0;
						right: 10px;
						color: #000;
						pointer-events: none;
					}
				}
			}
		}

		&.dropdownLeft {
			display: none;

			@include md {
				flex: 0 0 32.5%;
				display: block;
				padding: 0;
				background-color: $search-bg;

				ul {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					padding: 1rem;

					@include xl {
						padding: 30px;
					}

					li {
						list-style-type: none;

						&:nth-child(1) {
							box-shadow: inset -1px -1px 0 0 rgba(black, 0.2);
						}

						&:nth-child(2) {
							box-shadow: inset 0 -1px 0 0 rgba(black, 0.2);
						}

						&:nth-child(3) {
							box-shadow: inset -1px 0 0 0 rgba(black, 0.2);
						}

						a {
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: flex-start;
							height: 100%;
							color: white;
							font-size: rem(20px);
							font-weight: 600;
							line-height: (24px / 20px);
							text-align: center;
							border: 0;
							transition: transform 0.3s ease;

							@include xl {
								padding: 30px;
							}

							&:hover,
							&:focus {
								text-decoration: none;
								transform: scale(1.05);
							}

							img {
								width: 54px;
								height: 54px;
							}
						}
					}
				}
			}
		}
	}

	.dropdownViewAll {
		display: block;
		margin: 0 $nav-dropdown-col-spacing .375em;
		text-align: right;

		@media (max-width: $screen-md - 1) {
			display: none;
		}

		a {
			padding: $nav-dropdown-item-padding;
			font-size: $nav-font-size;
			font-weight: $nav-font-weight;
			text-decoration: $nav-dropdown-item-decoration;
			color: $nav-dropdown-item-text-color;

			&:active,
			&:focus,
			&:hover {
				text-decoration: $nav-dropdown-item-hover-decoration;
				background-color: $nav-dropdown-item-hover-bg;
			}
		}
	}
}

// -----------------------------------------------------
// MAIN NAV DROP DOWN IMAGE RIGHT
// -----------------------------------------------------

#mainNav .dropdown a.dropDownRightInner {
	display: block;
	float: left;
	width: 100%;
	background: #fff;
	border: 1px solid $nav-dropdown-item-divider;
	position: relative;

	&:before {
		position: absolute;
		content: "";
		bottom: 2px;
		right: 2px;
		left: 2px;
		width: 100%;
		width: calc(100% - 4px);
		height: 100%;
		background: linear-gradient(to bottom, rgba(black, 0) 75%, rgba(black, .75) 100%);
	}

	img {
		border: 2px solid #fff;
		width: 100%;
		height: auto;
	}

	.dropDownRightOverlay {
		display: block;
		width: 100%;
		color: #fff;
		font-size: 1.25em;
		line-height: 1.3;
		font-weight: 500;
		position: absolute;
		right: 2px;
		bottom: 2px;
		left: 2px;
		padding: 8px 10px;
		text-align: center;
	}

	&:hover .dropDownRightOverlay,
	&:focus .dropDownRightOverlay {
		text-decoration: underline;
	}
}