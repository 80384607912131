//  ======================================================
//  SITECONTENT.SCSS
//  Text and content styles
//  ======================================================

::placeholder {
    color: $text-color;
}

::selection {
    color: white;
    background-color: rgba(darken($brand-one, 10%), .5);
}

html {
    font-size: $font-size-base;
    scroll-behavior: smooth;
}

body {
    font-family: $font-family-base;
    font-size: em(18px);
    font-weight: $font-weight-base;
    line-height: $line-height-base;
    color: $text-color;
    background-color: $body-bg;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(black, 0);
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

a {
    font-weight: $link-font-weight;
    text-decoration: $link-decoration;
    color: $link-color;

    &:active,
    &:focus,
    &:hover {
        text-decoration: $link-hover-decoration;
        color: $link-hover-color;
    }

    &:visited {
        color: $link-visited-color;

        &:active,
        &:focus,
        &:hover {
            color: $link-visited-hover-color;
        }
    }
}

b,
strong {
    font-family: $bold-text-font-family;
    font-weight: $bold-text-font-weight;
}

caption,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;
}

caption {
    margin: 20px 0 15px 0;
    padding: 0;
    font-size: $font-size-caption;
    font-weight: $font-weight-caption;
    color: $text-color-caption;
}

h1 {
    margin: 0;
    padding: 0;
    font-size: $font-size-h1;
    font-weight: $font-weight-h1;
    color: $text-color-h1;

    @media (min-width: $screen-sm) {
        font-size: $font-size-h1;
    }
}

h2 {
    margin: 20px 0 15px 0;
    padding: 0;
    font-size: $font-size-h2;
    font-weight: $font-weight-h2;
    color: $text-color-h2;
}

h3 {
    margin: 20px 0 15px 0;
    padding: 0;
    font-size: $font-size-h3;
    font-weight: $font-weight-h3;
    color: $text-color-h3;
}

h4 {
    margin: 20px 0 15px 0;
    padding: 0;
    font-size: $font-size-h4;
    font-weight: $font-weight-h4;
    color: $text-color-h4;
}

h5 {
    margin: 0 0 15px 0;
    padding: 0;
    font-size: $font-size-h5;
    font-weight: $font-weight-h5;
    color: $text-color-h5;
}

h6 {
    margin: 0 0 15px 0;
    padding: 0;
    font-size: $font-size-h6;
    font-weight: $font-weight-h6;
    color: $text-color-h6;
}

p {
    margin: 15px 0 15px 0;
}

small,
sub,
sup {
    font-size: 80%;
}

#printArea {
    li {
        position: relative;
        margin: 5px 0;
    }

    ol,
    ul {
        margin: 10px 0px 10px 30px;
        padding: 0;
    }

    ol {
        counter-reset: custom-ol-counter;

        li {
            list-style-type: none;
            counter-increment: custom-ol-counter;

            &:before {
                position: absolute;
                top: -3px;
                left: -20px;
                font-size: rem(18px);
                font-weight: 500;
                line-height: (36px / 18px);
                content: counter(custom-ol-counter) ". ";
            }
        }
    }

    ul {
        li {
            list-style-type: none;

            &:before {
                position: absolute;
                top: 2px;
                left: -20px;
                font-size: rem(21px);
                font-weight: 600;
                line-height: (25px / 21px);
                content: '>';
            }

            ul {
                li {
                    list-style-type: disc;

                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}

/*==================
  Block Quotes
====================*/

blockquote {
    position: relative;
    padding: 6px 0 6px 28px;
    margin: 0;
    color: $text-color;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        background-color: $brand-one;
        border-radius: 6px;
        content: '';
    }

    p {
        margin: 0;
    }

    .blockquote-FooterLine {
        display: block;
        font-weight: 600;
    }
}

/*==================
  Emphasis
====================*/

.emphasis-Primary,
.emphasis-Secondary,
.emphasis-Tertiary,
.emphasis-quaternary,
.emphasis-Grey {
    position: relative;
    display: block;
    overflow: hidden;
    padding: 24px 36px 32px 80px;
    margin-top: 15px;
    margin-bottom: 15px;
    color: $text-color;
    font-size: em(18px, 18px);
    font-weight: 400;
    line-height: (30px / 18px);
    border-radius: $border-radius-base;

    &:before {
        position: absolute;
        top: 32px;
        left: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'icomoon';
        font-size: em(32px, 18px);
        line-height: 1;
        content: $icon-info-circle;

        @include xl {
            top: 30px;
            left: 32px;
        }
    }

    strong {
        display: block;
        margin-bottom: 10px;
        font-size: em(21px, 18px);
        font-weight: 600;
        line-height: (30px / 21px);
    }

    a:not([class]) {
        color: #005EA9;
        font-weight: 600;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }

        &:focus {
            outline: 1px dotted -webkit-focus-ring-color;
            outline-offset: 1px;
        }
    }

    a.defaultButton-quaternary {
        margin-top: 25px;
        border-color: white;
    }
}

.emphasis-Primary {
    background-color: $emphasis-primary;
}

.emphasis-Secondary {
    background-color: $emphasis-secondary;
}

.emphasis-Tertiary {
    background-color: $emphasis-tertiary;
}

.emphasis-Grey {
    background-color: $emphasis-grey;
}

/*==================
  Emphasis
====================*/

.emphasis-Primary-with-btn,
.emphasis-Secondary-with-btn,
.emphasis-Tertiary-with-btn,
.emphasis-quaternary-with-btn,
.emphasis-Grey-with-btn {
    position: relative;
    display: block;
    overflow: hidden;
    padding: 24px 44px 30px 30px;
    margin-top: 15px;
    margin-bottom: 15px;
    color: white;
    font-size: em(18px, 18px);
    font-weight: 400;
    line-height: (30px / 18px);
    border-radius: $border-radius-base;

    a:not([class]) {
        color: white;
        text-decoration: underline;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    strong {
        display: block;
        margin-bottom: 10px;
        font-size: em(21px, 18px);
        font-weight: 600;
        line-height: (30px / 21px);

        +p {
            margin: 10px 0 0 0;

            +p {
                display: flex;
                margin: 24px 0 0 0;
            }
        }
    }

    >a[class] {
        margin-top: 24px;
    }

    a.defaultButton-quaternary {
        border-color: white;
    }
}

.emphasis-Primary-with-btn {
    background-color: $emphasis-primary-alt;
}

.emphasis-Secondary-with-btn {
    background-color: $emphasis-secondary-alt;
}

.emphasis-Tertiary-with-btn {
    background-color: $emphasis-tertiary-alt;
}

.emphasis-Grey-with-btn {
    color: $brand-dark;
    background-color: $emphasis-grey-alt;

    a:not([class]) {
        color: $brand-dark;
    }
}

/*==================
  Horizontal Dividers
====================*/

hr {
    display: block;
    border: none;
    height: 1px;
    color: $gray-light;
    background-color: $gray-light;
    margin: 20px 0;
}

/* fix for showing bullets on photo gallery images */

#contentInt .ad-nav ul li {
    list-style: none !important;
}

// -----------------------------------------------------
// TABLE STYLES
// -----------------------------------------------------

#contentInt {
    table:not(.datatable) {
        td p {
            margin-right: 10px !important;
            margin-top: 10px !important;
        }

        td:last-child p {
            margin-right: 0px !important;
        }
    }
}

.responaive-table {
    overflow: auto;
}

.datatable {
    margin: 0px 0px 15px 0px;
    padding: 0;
    width: 100%;
    border: $table-border;
    border-collapse: separate;
    border-radius: $table-border-radius;

    td,
    th {
        padding: $table-cell-padding;
        color: $table-text-color;
    }

    tr:first-child {

        td,
        th {
            &:first-child {
                border-radius: $table-border-radius 0 0 0;
            }

            &:last-child {
                border-radius: 0 $table-border-radius 0 0;
            }
        }
    }

    tr:last-child {

        td,
        th {
            border-bottom: 0;

            &:first-child {
                border-radius: 0 0 0 $table-border-radius;
            }

            &:last-child {
                border-radius: 0 0 $table-border-radius 0;
            }
        }
    }

    thead tr:first-child,
    thead tr:last-child {

        td,
        th {
            border-bottom: $table-titlerow-border-bottom;

            &:first-child {
                border-radius: $table-border-radius 0 0 0;
            }

            &:last-child {
                border-radius: 0 $table-border-radius 0 0;
            }
        }
    }

    .titlerow {
        background: $table-titlerow-bg;
        text-align: $table-titlerow-text-align;
        font-size: $table-titlerow-font-size;
        color: $table-titlerow-text-color;
        font-weight: $table-titlerow-font-weight;

        td,
        th {
            padding: $table-titlerow-padding;
            color: $table-titlerow-text-color;
            border-bottom: $table-titlerow-border-bottom;
        }

        * {
            color: $table-titlerow-text-color !important;
        }

        a {
            text-decoration: underline;
            color: $link-color !important;
        }
    }

    caption {
        text-align: left;
        color: $text-color;
    }

    .altrow {
        background: $table-altrow-bg;
    }

    .row {
        background: $table-row-bg;
    }
}

td p,
#printArea td p,
#printArea .datatable td p {
    margin: 7px 0 0 0;

    &:first-child {
        margin: 0 !important;
    }
}

// -----------------------------------------------------
// BUTTONS
// -----------------------------------------------------
#pageSubscription {
    display: block;
    width: 100%;
    clear: both;
    padding: 10px 20px 10px 20px;
    color: white;
    font-weight: bold;
    margin: 20px 0 0;
    text-align: left;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
    border-radius: 3px;
    background-color: $brand-one;
    overflow: hidden;

    &:before {
        @include icomoon;
        content: $icon-subscribe;
        display: inline-block;
        position: relative;
        margin-right: 0.7em;
        font-size: em(20px);
        line-height: 0.85;
    }

    &:active,
    &:focus,
    &:hover {
        text-decoration: none;
        background-color: lighten($brand-one, 10%);
    }
}

button.defaultButton-Primary,
a.defaultButton-Primary,
.defaultButton-Primary a,

button.defaultButton-Secondary,
a.defaultButton-Secondary,
.defaultButton-Secondary a,

button.defaultButton-Tertiary,
a.defaultButton-Tertiary,
.defaultButton-Tertiary a,

button.defaultButton-quaternary,
a.defaultButton-quaternary,
.defaultButton-quaternary a,

button.defaultButton-Quinary,
a.defaultButton-Quinary,
.defaultButton-Quinary a,

button.defaultButton-lightGrey,
a.defaultButton-lightGrey,
.defaultButton-lightGrey a,

button.defaultButton-darkGrey,
a.defaultButton-darkGrey,
.defaultButton-darkGrey a,

button.defaultButton-White,
a.defaultButton-White,
.defaultButton-White a,
.interior #printAreaContent button.feedbackLink,
.interior #printAreaContent a.feedbackLink,
.interior #printAreaContent .feedbackLink a {
    display: inline-block;
    overflow: hidden;
    max-width: 100%;
    padding: 12px 30px;
    margin: 0;
    font-size: em(18px, 18px);
    font-weight: 600 !important;
    line-height: (26px / 18px);
    text-align: center;
    border: 2px solid;
    border-radius: 6px;
    transition: all 0.3s ease;

    &:hover,
    &:focus {
        text-decoration: underline;
        cursor: pointer;
    }

    &:first-child {
        margin-left: 0;
    }
}

.defaultButton-Primary a,
.defaultButton-Secondary a,
.defaultButton-Tertiary a,
.defaultButton-quaternary a,
.defaultButton-Quinary a,
.defaultButton-lightGrey a,
.defaultButton-darkGrey a,
.defaultButton-White a,
.interior #printAreaContent .feedbackLink a {
    margin: 0;
}

p.defaultButton-Primary a[class],
button.defaultButton-Primary,
a.defaultButton-Primary,
.defaultButton-Primary a,
.interior #printAreaContent button.feedbackLink,
.interior #printAreaContent a.feedbackLink,
.interior #printAreaContent .feedbackLink a {
    color: color-contrast($brand-one, $colors: (#fff, #000), $tolerance: 0) !important;
    background-color: $brand-one; //Update class name here and icreate.css to match brand color
    border-color: $brand-one;

    &:active,
    &:focus,
    &:hover {
        background-color: darken($brand-one, 10%);
        border-color: darken($brand-one, 10%);
        text-decoration: none;
    }
}

p.defaultButton-Secondary a[class],
button.defaultButton-Secondary,
a.defaultButton-Secondary,
.defaultButton-Secondary a {
    color: color-contrast($brand-two, $colors: (#fff, #000), $tolerance: 0) !important;
    background-color: $brand-two; //Update class name here and icreate.css to match brand color
    border-color: $brand-two;

    &:active,
    &:focus,
    &:hover {
        text-decoration: none;
        background-color: darken($brand-two, 10%);
        border-color: darken($brand-two, 10%);
    }
}

p.defaultButton-Tertiary a[class],
button.defaultButton-Tertiary,
a.defaultButton-Tertiary,
.defaultButton-Tertiary a {
    color: color-contrast($brand-three, $colors: (#fff, #000), $tolerance: 0) !important;
    background-color: $brand-three; //Update class name here and icreate.css to match brand color
    border-color: $brand-three;

    &:active,
    &:focus,
    &:hover {
        text-decoration: none;
        background-color: lighten($brand-three, 10%);
        border-color: lighten($brand-three, 10%);
    }
}

p.defaultButton-quaternary a[class],
button.defaultButton-quaternary,
a.defaultButton-quaternary,
.defaultButton-quaternary a {
    color: color-contrast($brand-four, $colors: (#fff, #000), $tolerance: 0) !important;
    background-color: $brand-four; //Update class name here and icreate.css to match brand color
    border-color: #00A3E0;

    &:active,
    &:focus,
    &:hover {
        background-color: darken($brand-four, 5%);
        border-color: #00A3E0;
        text-decoration: none;
    }
}

p.defaultButton-Quinary a[class],
button.defaultButton-Quinary,
a.defaultButton-Quinary,
.defaultButton-Quinary a {
    color: color-contrast($brand-five, $colors: (#fff, #000), $tolerance: 0) !important;
    background-color: $brand-five; //Update class name here and icreate.css to match brand color
    border-color: $brand-five;

    &:active,
    &:focus,
    &:hover {
        background-color: darken($brand-five, 10%);
        border-color: darken($brand-five, 10%);
        text-decoration: none;
    }
}

p.defaultButton-lightGrey a[class],
button.defaultButton-lightGrey,
a.defaultButton-lightGrey,
.defaultButton-lightGrey a {
    color: color-contrast($gray-border, $colors: (#fff, #000), $tolerance: 0) !important;
    background-color: $gray-background; //Should always have a gray button
    border-color: $gray-background;

    &:active,
    &:focus,
    &:hover {
        background-color: darken($gray-background, 10%);
        border-color: darken($gray-background, 10%);
        text-decoration: none;
    }
}

p.defaultButton-darkGrey a[class],
button.defaultButton-darkGrey,
a.defaultButton-darkGrey,
.defaultButton-darkGrey a {
    background-color: $gray-dark; //Should always have a gray button
    border-color: $gray-dark; //Should always have a gray button
    color: color-contrast($gray-dark, $colors: (#fff, #000), $tolerance: 0) !important;

    &:active,
    &:focus,
    &:hover {
        background-color: lighten($gray-dark, 10%);
        border-color: lighten($gray-dark, 10%);
        text-decoration: none;
    }
}

p.defaultButton-White a[class],
button.defaultButton-White,
a.defaultButton-White,
.defaultButton-White a {
    background-color: white; //Should always have a gray button
    color: #000 !important;

    &:active,
    &:focus,
    &:hover {
        background: darken(white, 5%);
        text-decoration: none;
    }
}


// -----------------------------------------------------
// DIALOGS
// -----------------------------------------------------

/*Make sure the dialog windows don't overflow on devices*/

div.ui-dialog {
    max-width: 100%;
    z-index: 9999;
}

.ui-widget-overlay.ui-front {
    z-index: 9998;
}

.feedbackModal div.ffLogo img {
    max-width: 200px !important;
    height: 100%; //IE11 fix for logo stretch
}

// -----------------------------------------------------
// HELPER CLASSES
// -----------------------------------------------------
//scrollable area when tables are wider than content width
#printAreaContent .tableContainerHead {
    padding: .5em;
    background: #eaeaea;
    border: solid 1px #ccc;
    margin-top: 30px;
}

#printAreaContent .tableContainer {
    max-width: 100%;
    padding: 20px;
    margin-bottom: 30px;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    border: solid 1px #ccc;
}

// headingBackgroundColour MUST SUPPORT WHITE TEXT
.headingBackgroundColour {
    background-color: $brand-one;
    color: color-contrast($brand-one, $colors: (#fff, #000), $tolerance: 0) !important;
}

#printAreaContent img {
    max-width: 100%;
    height: auto;
}

img {

    &.ImageRight,
    &.imageRight,
    &.Right {
        float: right;
        margin: 0 0 20px 40px;
        padding: 1px;
    }

    &.ImageLeft,
    &.imageLeft,
    &.Left {
        float: left;
        margin: 0 40px 20px 0;
        padding: 1px;
    }

    &.fullWidthImage {
        width: 100%;
        height: auto;
    }
}

.fullWidthImage {
    img {
        width: 100%;
        height: auto;
    }
}

.Left iframe {
    float: left;
    margin: 0 20px 20px 0;
    padding: 1px;
}

.Right iframe {
    float: right;
    margin: 0 0 20px 20px;
    padding: 1px;
}

.iframe-container-wrapper {
    width: 100%;
}

.Left .iframe-container-wrapper {
    float: left;
    margin: 0 20px 20px 0;
}

.Right .iframe-container-wrapper {
    float: right;
    margin: 0 20px 20px 0;
}

#printAreaContent iframe {
    border: 1px solid $gray-light;
}

img.Center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    clear: both;
}

.Center,
.alignCenter {
    text-align: center;
}

.Left,
.alignLeft {
    text-align: left;
}

.Right,
.alignRight {
    text-align: right;
}

.inline {
    display: inline;
}

.inlineBlock {
    display: inline-block;
}

.noMarginTop {
    margin-top: 0;
}

.noMarginBottom {
    margin-bottom: 0;
}

.noMarginLeft {
    margin-left: 0;
}

.noMarginRight {
    margin-right: 0;
}

.width100 {
    width: 100%;
}

.Underline {
    text-decoration: underline;
}

.IntroParagraph {
    color: $brand-one;
    font-size: em(23px, 18px);
    font-style: italic;
    font-weight: 400;
    line-height: (34px / 23px);
}

.iframe-container {
    position: relative;
    height: 0;
    overflow: hidden;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.issuuembed {
    height: 750px !important;
}

.eSolutionsGroupPhotoGalleryV2PlaceholderDiv .ad-image-description {
    margin: 0px !important;
}

form.contactForm {
    fieldset {
        position: relative;

        +fieldset {
            margin-top: 16px;
        }
    }

    input,
    textarea {
        display: inline-block;
        margin-bottom: 16px;

        +.VaildationMark {
            margin-top: -44px;
        }
    }

    input[type="file"] {
        padding: 8px;
        line-height: 1;
    }

    label {
        display: block;
    }

    .label {
        display: block;
        max-width: 100%;
        margin-bottom: 5px;
        font-weight: 700;
    }

    .button,
    input[type="file"],
    input[type="submit"] {
        display: inline-block;
        background-color: $gray-lighter;
        border: solid 1px $gray-light;
        border-radius: $border-radius-small;

        &:focus,
        &:hover {
            box-shadow: inset 0 0 0 99px rgba(black, .05);
        }
    }

    .VaildationMark {
        display: inline-block;
        position: absolute;
        right: 16px;
        width: 24px;
        height: 24px;
        font-weight: bold;
        line-height: 20px;
        text-align: center;
        color: $brand-five;
        background-color: white;
        border: solid 2px $brand-five;
        border-radius: 50%;
        box-shadow: 0 0 16px rgba(white, .5);
        visibility: hidden;
    }

    .ic-row .VaildationMark {
        right: 32px;
    }

    .datatable {
        width: 100%;
        border: 1px solid #ccc;

        td {
            padding: 7px 10px;
            color: #333;
        }
    }
}

form.contactForm input[type="text"],
form.contactForm input[type="date"],
form.contactForm select {
    width: 100%;
    height: 30px;
    padding: 3px 7px;
    -webkit-appearance: none;
    border: solid 1px #ccc;
}

form.contactForm textarea {
    width: 100%;
    padding: 3px 7px;
    -webkit-appearance: none;
    border: solid 1px #ccc;
}

.badge {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    color: $brand-dark;
    font-size: rem(17px);
    line-height: 1;
    background-color: #FFC117;
    border-radius: 50%;
}

// Chat Button
#comm100-container {
    position: fixed;
    z-index: 1001;
}