.emergencyBarWrapper {
    position: relative;
    display: none;
    width: 100%;
    border-bottom: 2px solid white;

    &.red {
        background-color: #B41D1D;
    }

    &.orange {
        background-color: #F5A92E;
    }

    &.grey {
        background-color: #E9E9E9;
    }
}

.emergencyBar {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 0.5rem;

    @include xl {
        padding: 24px 0 32px 0;
    }

    &:before,
    &:after {
        display: none;
    }
}

.emergencyBarText {
    max-width: calc(100% - 44px);

    @include sm {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: calc(100% - 44px);
    }

    .red & {
        color: white;

        a:not([class]) {
            color: white;
        }
    }

    .orange &,
    .grey & {
        color: $brand-dark;

        a:not([class]) {
            color: $brand-dark;
        }
    }

    i {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 0 0 44px;
        width: 44px;
        height: 44px;
        font-size: rem(36px);

        @include sm {
            justify-content: center;
            margin: 0.375rem 1rem 0 0;
        }

        .orange & {
            font-size: rem(32px);
        }
    }

    .text {
        max-width: 100%;

        @include sm {
            max-width: 70%;
        }

        p {
            @extend %text-truncate;
            max-width: 100%;
            margin: 0.5rem 0 0 0;
            font-size: rem(18px);
            font-weight: 400;
            line-height: (26px / 18px);

            @include sm {
                margin-top: 0;
                line-height: 1.25;
            }

            &:first-child {
                @include sm {
                    margin-top: 0;
                }
            }

            strong {
                font-size: 1.5rem;
                font-weight: 600;
                line-height: (34px / 24px);
            }
        }

    }

    >p {
        max-width: 100%;

        @include sm {
            max-width: 28%;
        }

        @include sm {
            padding: 0 1rem;
            margin: 0.375rem 0 0 auto;
        }

        @include xl {
            padding: 0 40px 0 0;
        }

        .btn {
            @extend %text-truncate;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            max-width: 100%;
            padding: 10px 18px;
            font-size: rem(18px);
            font-weight: 600;
            line-height: (26px / 18px);
            border: 2px solid;
            border-radius: 6px;
            transition: all 0.3s ease;

            &:hover,
            &:focus {
                text-decoration: none;
            }

            .red & {
                color: white;
                background-color: #931913;
                border-color: #931913;

                &:hover,
                &:focus {
                    border-color: darken(#931913, 10%);
                }
            }

            .orange & {
                color: $brand-dark;
                background-color: #DE9C0D;
                border-color: #DE9C0D;

                &:hover,
                &:focus {
                    border-color: darken(#DE9C0D, 10%);
                }
            }

            .grey & {
                color: $brand-dark;
                background-color: #D9D9D9;
                border-color: #D9D9D9;

                &:hover,
                &:focus {
                    border-color: darken(#D9D9D9, 10%);
                }
            }
        }
    }
}

.emergencyBarButtons {
    .closeBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
        padding: 0;
        margin: 0;
        background-color: transparent;
        border: 0;
        border-radius: 50%;
        transition: all 0.3s ease;

        i {
            font-size: rem(25px);
        }

        .red & {
            color: white;

            &:hover,
            &:focus {
                background-color: $brand-one;
            }
        }

        .orange &,
        .grey & {
            color: $brand-dark;

            &:hover,
            &:focus {
                color: white;
                background-color: $brand-one;
            }
        }
    }
}